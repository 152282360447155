import { Injectable } from '@angular/core';
import { CompanyByIdGQL } from "../../../../graphql/generated";
import { Store } from "../../core/services/store.service";

interface CompanyState {
    companyId: string;
}

@Injectable({
    providedIn: 'root'
})
export class CompanyService extends Store<CompanyState> {

    // ----------[ Dependencies ]----------

    constructor(
        private readonly companyByIdGQL: CompanyByIdGQL,
    ) {
        super();
    }

    // ----------[ Methods ]----------

    isCurrentCompany(companyId: string) {
        return this.value('companyId') === companyId;
    }

}
